import React from 'react'
import Styled from 'styled-components'

const Path = Styled.path`
  @media (prefers-color-scheme: dark) {
  fill: ${p => p.fill || '#ffffff'};
  }
  @media (prefers-color-scheme: light) {
  fill: ${p => p.fill || '#000000'};
  }
`

export default ({ fill, size = '100px' } = {}) => (
  <svg
    width={ size }
    height={ size }
    viewBox="0 0 100 100"
  >
    <g
      id="Icon-email"
      stroke="none"
      fill="none"
    >
      <Path
        d="M81.2,19 L89,19 L89,26.75 L89,73.25 L89,81 L81.2,81 L18.8,81 L11,81 L11,73 L11.0387903,27 L11,27 L11,19 L18.8,19 L81.2,19 Z M18.8,27 L18.8,34.5 L49.5125,53.875 L80.225,34.5 L80.225,26.75 L49.5125,46.125 L19,26.8761701 L19,27 L18.8,27 Z"
        fill={ fill }
      />
    </g>
  </svg>
)
