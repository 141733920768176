import React, { useState } from "react"
import { OutboundLink } from 'gatsby-plugin-google-analytics'
// import { Link } from "gatsby"
import Styled, { keyframes } from 'styled-components'

import SEO from "../components/seo"
import LogoMain from '../components/logo-main.js'
import BackgroundGrid from '../components/backgrounds/isometric-grid'
// import { email, github, linkedin } from '../components/icons'
import Email from '../components/icons/email'
import Github from '../components/icons/github'
import Linkedin from '../components/icons/linkedin'
import Twitter from '../components/icons/twitter'


const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Page = Styled.article`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  max-width: 100vw;
  flex-wrap: wrap;
  padding: 3rem;
  overflow: hidden;
  opacity: 1;
  animation: ${fadeIn} 2s linear;
`
const Brand = Styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 100px;
  width: 100%;
`
const Links = Styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* grid-template-columns: repeat(1fr); */
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`
const Wrapper = Styled.div`
  flex: 1 1 0;
  width: 100%;
  max-width: 1000px;
`
const Name = Styled.h1`
  text-align: center;
`
const Services = Styled.h2`
  text-align: center;
`

const ContactLink = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  text-decoration: none;
  margin: 1rem;
`

const IndexPage = () => {
  const [githubFill, setGithubFill] = useState(null)
  const [emailFill, setEmailFill] = useState(null)
  const [linkedinFill, setLinkedinFill] = useState(null)
  const [TwitterFill, setTwitterFill] = useState(null)

  const theme = typeof window !== 'undefined' ? (window.matchMedia('(prefers-color-scheme: light)').matches
    ? 'light'
    : 'dark') : 'dark'

  const hoverColor = theme === 'light' ? '#cc0000' : '#dd0000'
  const iconSize = '40px'

  return (
    <>
      <SEO title="Martin Banks" />
      <BackgroundGrid theme={ theme } />
      <Page>
        <Wrapper>
          <Brand>
            <LogoMain />
            <Name>Martin Banks</Name>
            <Services>Web Designer & Developer</Services> 
          </Brand>

          <Links>
            <ContactLink
              onMouseOver={ () => setGithubFill(hoverColor) }
              onMouseOut={ () => setGithubFill(null) }
            >
              <OutboundLink href="https://github.com/martin-banks" >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Github
                    fill={ githubFill }
                    size={ iconSize }
                  />
                  <span style={{ opacity: 0, width: 0, height: 0, overlfow: 'hidden' }}>GithHub profile</span>
                </div>
              </OutboundLink>
            </ContactLink>

            <ContactLink
              onMouseOver={ () => setTwitterFill(hoverColor) }
              onMouseOut={ () => setTwitterFill(null) }
            >
              <OutboundLink href="https://twitter.com/anotherbanksy">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Twitter
                    fill={ TwitterFill }
                    size={ iconSize }
                  />
                  <span style={{ opacity: 0, width: 0, height: 0, overlfow: 'hidden' }}>@anotherbanksy Twitter profile</span>
                </div>
              </OutboundLink>
            </ContactLink>

            <ContactLink
              onMouseOver={ () => setLinkedinFill(hoverColor) }
              onMouseOut={ () => setLinkedinFill(null) }
            >
              <OutboundLink href="https://www.linkedin.com/in/martin-banks-6b148221/">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Linkedin
                    fill={ linkedinFill }
                    size={ iconSize }
                  />
                  <span style={{ opacity: 0, width: 0, height: 0, overlfow: 'hidden' }}>LinkedIn profile</span>
                </div>
              </OutboundLink>
            </ContactLink>

            <ContactLink
              onMouseOver={ () => setEmailFill(hoverColor) }
              onMouseOut={ () => setEmailFill(null) }
            >
              <OutboundLink href="mailto:email@martinbanks.io" >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Email
                    fill={ emailFill }
                    size={ iconSize }
                  />
                  <span style={{ opacity: 0, width: 0, height: 0, overlfow: 'hidden' }}>Email me</span>
                </div>
              </OutboundLink>
            </ContactLink>

          </Links>
        </Wrapper>
      </Page>
    </>
  )
}

export default IndexPage
