import React from 'react'
import Styled from 'styled-components'

const Path = Styled.path`
  @media (prefers-color-scheme: dark) {
  fill: ${p => p.fill || '#ffffff'};
  }
  @media (prefers-color-scheme: light) {
  fill: ${p => p.fill || '#000000'};
  }
`

export default ({ fill, size = '100px' } = {}) => (
  <svg width={ size } height={ size } viewBox="0 0 100 100">

    <g id="Icon-linkedin-mark">
      <Path d="M93.2129452,76.740977 L93.1220344,76.753903 L94.522094,78.872419 L93.7961496,78.872419 L92.4886781,76.8262548 L92.4722403,76.800235 L91.6313994,76.800235 L91.6313994,78.872419 L90.9517491,78.872419 L90.9517491,74.1148229 L92.7590622,74.1148229 C93.8850476,74.1148229 94.4350411,74.5539701 94.4350411,75.4584522 C94.4350411,76.199765 94.0147045,76.6427732 93.2129452,76.740977 Z M92.4241012,76.2627161 C93.1518907,76.2627161 93.7480105,76.199765 93.7480105,75.4215209 C93.7480105,74.7413127 93.1572581,74.655867 92.6351082,74.655867 L91.6313994,74.655867 L91.6313994,76.2627161 L92.4241012,76.2627161 Z M92.5017612,80.4107772 C94.655441,80.4107772 96.3406452,78.7092496 96.3406452,76.5000839 C96.3406452,74.2925969 94.655441,72.5895585 92.5017612,72.5895585 C90.3499264,72.5895585 88.6610321,74.2925969 88.6610321,76.5000839 C88.6610321,78.7092496 90.3499264,80.4107772 92.5017612,80.4107772 Z M92.5017612,72 C95.0221034,72 97,73.9758268 97,76.5000839 C97,79.0261877 95.0221034,81 92.5017612,81 C89.9775612,81 88,79.0261877 88,76.5000839 C88,73.9758268 89.9775612,72 92.5017612,72 Z" fill={ fill }></Path>
      <Path d="M86,80.8046781 L86,86 L14,86 L14,80.8046781 L14,19.19143 L14,14 L86,14 L86,19.19143 L86,80.8046781 Z M30.1312537,24 C26.7371012,24 24,26.7506844 24,30.1394475 C24,33.5292631 26.7371012,36.2799475 30.1312537,36.2799475 C33.5119256,36.2799475 36.256555,33.5292631 36.256555,30.1394475 C36.256555,26.7506844 33.5119256,24 30.1312537,24 Z M24.8389529,75 L35.4177772,75 L35.4177772,40.9341693 L24.8389529,40.9341693 L24.8389529,75 Z M42.0476406,40.9341693 L42.0476406,75 L52.6031802,75 L52.6031802,58.1510716 C52.6031802,53.7069703 53.4423081,49.4012539 58.9413709,49.4012539 C64.364977,49.4012539 64.4327305,54.483434 64.4327305,58.4338047 L64.4327305,75 L75,75 L75,56.3171651 C75,47.1423708 73.0225433,40.0887762 62.3220428,40.0887762 C57.1803052,40.0887762 53.732055,42.9131256 52.3228873,45.5911973 L52.1781014,45.5911973 L52.1781014,40.9341693 L42.0476406,40.9341693 Z" fill={ fill }></Path>
    </g>
</svg>
)
