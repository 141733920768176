import React from 'react'
import Styled from 'styled-components'

const Path = Styled.path`
  @media (prefers-color-scheme: dark) {
  fill: ${p => p.fill || '#ffffff'};
  }
  @media (prefers-color-scheme: light) {
  fill: ${p => p.fill || '#000000'};
  }
`

export default ({ fill, size = '100px' } = {}) => (
  <svg width={ size } height={ size } viewBox="0 0 100 100">
    <g id="Icon-twitter-mark">
      <Path
        d="M35.4787265,86 C68.4518003,86 86.4866806,58.6820495 86.4866806,34.9920459 C86.4866806,34.2161266 86.4866806,33.4437025 86.4342537,32.6747735 C89.9427766,30.1369962 92.9713926,26.9947627 95.3782961,23.3951984 C92.1064299,24.8449853 88.63559,25.7957551 85.0816376,26.2157698 C88.8240291,23.9753276 91.6249561,20.4515005 92.9631599,16.3000808 C89.4441127,18.3882659 85.5941851,19.8599146 81.5795155,20.6515199 C76.0225452,14.7426301 67.192572,13.2964085 60.0409378,17.1238132 C52.8893036,20.951218 49.194585,29.1003921 51.0285681,37.0017467 C36.61428,36.2791266 23.1845156,29.4708675 14.0815294,18.2713352 C9.32333645,26.4626825 11.7537293,36.9418383 19.6317988,42.2025029 C16.7788667,42.1179471 13.9881373,41.3483387 11.4951318,39.9586282 C11.4951318,40.032026 11.4951318,40.1089189 11.4951318,40.1858118 C11.4974671,48.7195012 17.5128803,56.0695614 25.8775994,57.7593347 C23.238319,58.4791223 20.4691645,58.5843406 17.782874,58.0669063 C20.1314206,65.3697146 26.8617396,70.3725101 34.5315458,70.5165659 C28.1834603,75.5056288 20.3415185,78.2139917 12.267556,78.205856 C10.8412051,78.2031177 9.41624163,78.1167563 8,77.9472162 C16.1983069,83.2083563 25.7374914,85.9990106 35.4787265,85.9860195"
        fill={ fill }
      />
    </g>
  </svg>
)
